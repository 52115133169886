import LayoutReport from '@/layout/Report.vue'
import LayoutMain from '@/layout/Main.vue'
import store from '../store'
export default [
  {
    path: '/reports',
    component: LayoutMain,
    children: [
      {
        path: 'TemplateFile/edit',
        name: 'TemplateFileEdit',
        component: () => import(/* webpackChunkName: "reportsConfigEdit" */ '../views/reports/templateFile/Edit.vue'),
        meta: {
          title: '在线编辑模板'
        }
      },
      {
        path: 'riskControlWarn/wxlist',
        name: 'riskControlWarnwxlist',
        component: () => import(/* webpackChunkName: "riskControlWarnwxlist" */ '../views/reports/riskControl/WarnWxList.vue'),
        meta: {
          title: '风险预警',
          keepAlive: true
        }
      },
      {
        path: 'riskControlWarn/wxshow',
        name: 'riskControlWarnwxshow',
        component: () => import(/* webpackChunkName: "riskControlWarnwxshow" */ '../views/reports/riskControl/WarnWxShow.vue'),
        meta: {
          title: '风险预警',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/reports',
    component: LayoutReport,
    children: [
      {
        path: 'riskControlModel/list',
        name: 'riskControlModelList',
        component: () => import(/* webpackChunkName: "riskControlModelList" */ '../views/reports/riskControl/ModelList.vue'),
        meta: {
          title: `${store.getters['app/rateTitle']}列表`,
          keepAlive: false
        }
      },
      {
        path: 'riskControlModel/edit',
        name: 'riskControlModelEdit',
        component: () => import(/* webpackChunkName: "riskControlModelEdit" */ '../views/reports/riskControl/ModelEdit.vue'),
        meta: {
          title: `${store.getters['app/rateTitle']}编辑`,
          keepAlive: false
        }
      },
      {
        path: 'riskControlModel/add',
        name: 'riskControlModelAdd',
        component: () => import(/* webpackChunkName: "riskControlModelAdd" */ '../views/reports/riskControl/ModelAdd.vue'),
        meta: {
          title: `${store.getters['app/rateTitle']}新增`,
          keepAlive: false
        }
      },
      {
        path: 'riskControlModel/show',
        name: 'riskControlModelShow',
        component: () => import(/* webpackChunkName: "riskControlModelShow" */ '../views/reports/riskControl/ModelShow.vue'),
        meta: {
          title: `${store.getters['app/rateTitle']}展示`,
          keepAlive: false
        }
      },
      {
        path: 'riskControlTemplate/list',
        name: 'riskControlTemplateList',
        component: () => import(/* webpackChunkName: "riskControlTemplateList" */ '../views/reports/riskControl/TemplateList.vue'),
        meta: {
          title: '模型列表',
          keepAlive: false
        }
      },
      {
        path: 'riskControlTemplate/edit',
        name: 'riskControlTemplateEdit',
        component: () => import(/* webpackChunkName: "riskControlTemplateEdit" */ '../views/reports/riskControl/TemplateEdit.vue'),
        meta: {
          title: '模型编辑',
          keepAlive: false
        }
      },
      {
        path: 'riskControlTemplate/show',
        name: 'riskControlTemplateShow',
        component: () => import(/* webpackChunkName: "riskControlTemplateShow" */ '../views/reports/riskControl/TemplateShow.vue'),
        meta: {
          title: '模型展示',
          keepAlive: false
        }
      },
      {
        path: 'riskControl/rank',
        name: 'riskControlrank',
        component: () => import(/* webpackChunkName: "riskControlrank" */ '../views/reports/riskControl/Rank.vue'),
        meta: {
          title: '统计分析',
          keepAlive: false
        }
      },
      {
        path: 'riskControl/trends',
        name: 'riskControlTrends',
        component: () => import(/* webpackChunkName: "riskControlTrends" */ '../views/reports/riskControl/Trends.vue'),
        meta: {
          title: '行业趋势',
          keepAlive: false
        }
      },
      {
        path: 'riskControlStandard/edit',
        name: 'riskControlStandardEdit',
        component: () => import(/* webpackChunkName: "riskControlStandardEdit" */ '../views/reports/riskControl/StandardEdit.vue'),
        meta: {
          title: '绩效标准编辑',
          keepAlive: false
        }
      },
      {
        path: 'riskControlOrg/list',
        name: 'riskControlOrgList',
        component: () => import(/* webpackChunkName: "riskControlOrgList" */ '../views/reports/riskControl/OrgList.vue'),
        meta: {
          title: '预警设置',
          keepAlive: false
        }
      },
      {
        path: 'riskControlWarn/list',
        name: 'riskControlWarnList',
        component: () => import(/* webpackChunkName: "riskControlWarnList" */ '../views/reports/riskControl/WarnList.vue'),
        meta: {
          title: '风险预警',
          keepAlive: false
        }
      },

      {
        path: 'riskControlWarn/show',
        name: 'riskControlWarnShow',
        component: () => import(/* webpackChunkName: "riskControlWarnShow" */ '../views/reports/riskControl/WarnShow.vue'),
        meta: {
          title: '风险预警详情',
          keepAlive: false
        }
      },
      {
        path: 'approval/list',
        name: 'reportsApprovalList',
        component: () => import(/* webpackChunkName: "reportsApprovalList" */ '../views/reports/approval/List.vue'),
        meta: {
          title: '我的审批',
          keepAlive: true
        }
      },
      {
        path: 'approval/status',
        name: 'reportsApprovalStatus',
        component: () => import(/* webpackChunkName: "reportsApprovalStatus" */ '../views/reports/approval/Status.vue'),
        meta: {
          title: '待审批列表'
        }
      },
      {
        path: 'approval/fillInfo',
        name: 'reportsApprovalFillInfo',
        component: () => import(/* webpackChunkName: "reportsApprovalFillInfo" */ '../views/reports/mine/Info.vue'),
        meta: {
          title: '待审批列表'
        }
      },
      {
        path: 'approval/info',
        name: 'reportsApprovalInfo',
        component: () => import(/* webpackChunkName: "reportsApprovalInfo" */ '../views/reports/approval/Info.vue'),
        meta: {
          title: '填报信息'
        }
      },
      {
        path: 'approval/update',
        name: 'reportsApprovalUpdate',
        component: () => import(/* webpackChunkName: "reportsApprovalInfo" */ '../views/reports/mine/Update.vue'),
        meta: {
          title: '填报信息'
        }
      },
      {
        path: 'help/list',
        name: 'reportsHelpList',
        component: () => import(/* webpackChunkName: "reportsHelpList" */ '../views/reports/help/List.vue'),
        meta: {
          title: '帮忙填报'
        }
      },
      {
        path: 'help/edit',
        name: 'reportsHelpEdit',
        component: () => import(/* webpackChunkName: "reportsHelpEdit" */ '../views/reports/help/Edit.vue'),
        meta: {
          title: '帮忙填报'
        }
      },
      {
        path: 'mine/list',
        name: 'reportsMineList',
        component: () => import(/* webpackChunkName: "reportsMineList" */ '../views/reports/mine/List.vue'),
        meta: {
          title: '我的填报',
          keepAlive: true
        }
      },
      {
        path: 'mine/edit',
        name: 'reportsMineEdit',
        component: () => import(/* webpackChunkName: "reportsMineEdit" */ '../views/reports/mine/Edit.vue'),
        meta: {
          title: '我的填报'
        }
      },
      {
        path: 'mine/info',
        name: 'reportsMineInfo',
        component: () => import(/* webpackChunkName: "reportsMineInfo" */ '../views/reports/mine/Info.vue'),
        meta: {
          title: '我的填报'
        }
      },
      {
        path: 'mine/update',
        name: 'reportsMineUpdate',
        component: () => import(/* webpackChunkName: "reportsMineUpdate" */ '../views/reports/mine/Update.vue'),
        meta: {
          title: '我的填报'
        }
      },
      {
        path: 'manage/list',
        name: 'reportsManageList',
        component: () => import(/* webpackChunkName: "reportsManageList" */ '../views/reports/manage/List.vue'),
        meta: {
          title: '填报管理',
          keepAlive: true
        }
      },
      {
        path: 'manage/add',
        name: 'reportsManageAdd',
        component: () => import(/* webpackChunkName: "reportsManageAdd" */ '../views/reports/manage/Add.vue'),
        meta: {
          title: '填报新增'
        }
      },
      {
        path: 'manage/fillInfo',
        name: 'reportsManageFillInfo',
        component: () => import(/* webpackChunkName: "reportsManageFillInfo" */ '../views/reports/mine/Info.vue'),
        meta: {
          title: '填报详情'
        }
      },
      {
        path: 'config/list',
        name: 'reportsConfigList',
        component: () => import(/* webpackChunkName: "reportsConfigList" */ '../views/reports/config/List.vue'),
        meta: {
          title: '填报配置列表',
          keepAlive: true
        }
      },
      {
        path: 'config/info',
        name: 'reportsConfigInfo',
        component: () => import(/* webpackChunkName: "reportsConfigInfo" */ '../views/reports/config/Info.vue'),
        meta: {
          title: '填报配置'
        }
      },
      {
        path: 'status/update',
        name: 'reportsStatusUpdate',
        component: () => import(/* webpackChunkName: "reportsStatusUpdate" */ '../views/reports/mine/Update.vue'),
        meta: {
          title: '填报配置'
        }
      },
      {
        path: 'status/info',
        name: 'reportsStatusInfo',
        component: () => import(/* webpackChunkName: "reportsStatusInfo" */ '../views/reports/status/Info.vue'),
        meta: {
          title: '填报配置'
        }
      },

      {
        path: 'processConfig/info',
        name: 'ProcessConfigInfo',
        component: () => import(/* webpackChunkName: "reportsConfigInfo" */ '../views/reports/processConfig/Info.vue'),
        meta: {
          title: '流程配置'
        }
      },
      {
        path: 'TemplateFile/list',
        name: 'TemplateFileList',
        component: () => import(/* webpackChunkName: "reportsConfigList" */ '../views/reports/templateFile/List.vue'),
        meta: {
          title: '填报模板列表',
          keepAlive: true
        }
      },
      {
        path: 'TemplateFile/info',
        name: 'TemplateFileInfo',
        component: () => import(/* webpackChunkName: "reportsConfigInfo" */ '../views/reports/templateFile/Info.vue'),
        meta: {
          title: '填报模板'
        }
      },
      {
        path: 'userOrg/info',
        name: 'userOrgInfo',
        component: () => import(/* webpackChunkName: "reportsConfigInfo" */ '../views/reports/userOrg/Info.vue'),
        meta: {
          title: '单位信息'
        }
      },
      {
        path: 'dataSubmission/list',
        name: 'dataSubmissionList',
        component: () => import(/* webpackChunkName: "dataSubmissionList" */ '../views/reports/dataSubmission/List.vue'),
        meta: {
          title: '我的审批',
          keepAlive: true
        }
      }
    ]
  }
]
